<template>
  <div class="myDiv">
    <div class="myImgtwo">
     <div class="back">
          <img class="img2" src="../../assets/2.svg" alt="Norway" />
        </div>
    </div>
    <div class="center">{{username}}</div>
    <div class="login_box" v-if="showInput">
      <a-form-model ref="ruleForm" :model="formData">
        <a-form-model-item ref="password" prop="password">
          <a-input @keydown="keydown"
            type="password"
            class="pwd"
            v-model="formData.password"
            placeholder="请输入密码"
          />
        </a-form-model-item>

        <a-form-model-item>
          <a-button class="btn" @click="onSubmit('ruleForm')">确定</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="myButton" v-else>
      <button @click="showInput = true" class="button">登录</button>
    </div>
  </div>
</template>

<script>

import { checkPwd } from "@/request/api";

export default {
  data() {
    return {
      username:'123',
      formData: {},
      showInput:false
    };
  },
  mounted(){
    this.username = localStorage.getItem('username') || 'Adminstor'
  },
  methods: {
    onSubmit() {
      if(this.formData.password){
        checkPwd(this.formData).then((res) => {
          if (res.data.status === 200) {
            this.$router.back(-1);
          }
        });
      }else{
        this.$message.error('请输入密码')
      }
    },
    keydown(e){
      if(e.keyCode == 13){
        this.onSubmit()
      }
    }
  },
};
</script>

<style lang="less" scoped>
.myButton {
  text-align: center;
  .button {
    background-color: rgb(197, 194, 194);
    border-radius: 0.5vh;
    font-size: 2.8vh;
    width: 21vh;
    height: 7vh;
    margin-top: 2vh;
    padding: 1.1vh 5.4vh;
    border: none;
    opacity: 0.85;
    color: rgb(255, 255, 255);
    cursor: pointer;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.myDiv {
  width: 100%;
  height: 100vh;
  background: url("../../assets/2.png");
}
.center {
  text-align: center;
  width: 100%;
  font-size: 4vh;
  color: seashell;
}

/deep/.ant-form-item {
  text-align: center;
}
/deep/.ant-form-explain{
  text-align: left;
  margin-left: 41vw;
}
.btn {
  width: 39vh;
  height: 5vh;
  text-align: center;
  background-color: rgb(0, 153, 255);
  border-radius: 0.5vh;
  color: white;
  font-size: 1.8vh;
  padding: 0.8vh 3.9vh 1.3vh 4.3vh;
  border: none;
  margin: 2vh 0 0 0;
}
.myImgtwo {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 22vh;
  .img2 {
     margin: 2vh;
    width: 12.9vh;
  }
}
.pwd {
  width: 39vh;
  height: 5vh;
  font-size: 1.6vh;
  background-image: url("../../assets/1.svg");
  background-repeat: no-repeat;
  background-position: 0.3vh;
  padding: 1.6vh 0 1.5vh 4.5vh;
  border: none;
  border-radius: 5px;
}
.back{
  background: rgba(255, 255, 255, 0.58);
  width: 18vh;
  height: 18vh;
  border-radius: 9vh;
}
</style>